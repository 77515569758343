import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { MENU_OPEN } from 'store/actions';
import Table from '../../../components/Table/Table';
import UserIcon from '@mui/icons-material/Person';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getUsers, reportUser, searchUser } from '../../../services/user';
import AddButton from 'components/Buttons/AddButton';
import { Box, Grid, TextField } from '@mui/material';
import 'react-toggle/style.css';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';

const Users = () => {
    const dispatch = useDispatch();

    const initialValue = {
        users: []
    };

    const theme = useTheme();
    const [user, setUser] = useState(initialValue);
    const [searchTerm, setSearchTerm] = useState('');
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const getAllUsers = (page, rowsPerPage) => {
        getUsers((page ?? 0) + 1, rowsPerPage ?? 5).then((resp) => setUser(resp.data));
    };

    const debouncedSearch = useCallback(
        debounce((name) => {
            searchUser(name).then((resp) => {
                setUser(resp.data);
                dispatch({ type: 'SET_ROWS_PER_PAGE_USER', payload: parseInt(resp.data.total) });
            });
        }, 300),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (searchTerm) {
            debouncedSearch(searchTerm);
        } else {
            getAllUsers();
        }
    }, [searchTerm]);

    function formatDate() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        let dataAtual = `${dia}${mes}${ano}`;
        return dataAtual;
    }

    const download = (id, name) => {
        try {
            reportUser(id).then((resp) => {
                var blob = new Blob([resp.data], { type: 'application/pdf' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = name + formatDate() + '.pdf';
                link.click();
            });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getAllUsers();
    }, []);

    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'usuarios');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'usuarios' });
        }
    }, []);

    return (
        <Grid
            style={{
                backgroundColor: '#FFFFFF ',
                backgroundPosition: '50% 50%',
                backgroundSize: 'cover',
                width: '100%',
                height: '100%',
                padding: 0,
                margin: 0
            }}
            xs={12}
            md={12}
            sm={12}
            item
            container
            alignItems="center"
            padding={5}
        >
            <Grid container spacing={matchDownSM ? 2 : 2}>
                <Grid item xs={12} sm={6} md={8} sx={{ marginTop: 3 }}>
                    <h1
                        style={{
                            font: 'normal normal bold 35px/44px Roboto, sans-serif',
                            letterSpacing: '0px',
                            color: 'var(--unnamed-color-015641)',
                            Color: '#015641',
                            opacity: 1,
                            padding: 15,
                            marginLeft: '2%'
                        }}
                    >
                        <UserIcon /> USUÁRIOS
                    </h1>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{ marginTop: 8 }}>
                    <Box display="flex" justifyContent="right" alignItems="right" marginRight={2}>
                        <TextField
                            id="search"
                            label="Pesquisar"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Box>
                </Grid>
            </Grid>
            <hr style={{ width: '95%', marginTop: 0 }}></hr>
            <h3
                style={{
                    font: 'normal normal 300 18px/22px Roboto, sans-serif',
                    letterSpacing: '0px',
                    Color: '#00000',
                    opacity: 1,
                    padding: 15,
                    marginLeft: '2%'
                }}
            >
                Lista de usuários
            </h3>
            <Table
                action="Desativar"
                header1="Nome"
                header2="Email"
                header3="Permissão"
                login="Logar"
                header4="Ações"
                data={user.data ? user.data.map((value) => value) : []}
                getData={getAllUsers}
                total={user.total || 0}
                download={download}
            />
            <AddButton href="/novo_usuario" />
        </Grid>
    );
};

export default Users;
